/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

import { useTheme } from "../../core/StarberryComponentsMui"
import {
    MYACCOUNT_UNSUBSCRIBE,
    MYACCOUNT_EMAIL_UNSUBSCRIBE
} from "../../core/constants/urls";

import Unsubscribe from "../../core/components/MyAccount/pages/unsubscribe";
import PageHeaderHelmet from "../../core/components/pageHeader";

import _myAccount from "../../core/sample/myAccount.yaml"

const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <Unsubscribe path={MYACCOUNT_UNSUBSCRIBE} myAccount={myAccount} theme={theme} />
                <Unsubscribe path={MYACCOUNT_EMAIL_UNSUBSCRIBE} myAccount={myAccount} theme={theme} />
            </Router>
        </>
    )
}

export default MyAccountPages
